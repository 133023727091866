import * as React from 'react';
import theme from './theme';
import { Box, ChakraProvider, useColorMode } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useEffect } from 'react';
import IntroAnimation from './components/IntroAnimation/IntroAnimation';
import { motion } from 'framer-motion';
import { Analytics } from '@vercel/analytics/react';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: Infinity,
        },
    },
})

interface AppProps { }

const App: React.FC<React.PropsWithChildren<AppProps>> = ({ children }) => {
    const { setColorMode } = useColorMode();

    const [showContent, setShowContent] = React.useState(false);

    const handleAnimationComplete = () => {
        setShowContent(true);

        // @ts-ignore
        window.dataLayer = window.dataLayer || [];

        // @ts-ignore
        function gtag() { window.dataLayer.push(arguments); }

        // @ts-ignore
        gtag('event', 'animation_loaded');
    };

    useEffect(() => setColorMode('dark'));

    return (
        <ChakraProvider resetCSS theme={theme}>
            <Analytics />
            <QueryClientProvider client={queryClient}>
                <Box
                    as={motion.div}
                    minHeight="100vh"
                    position="relative"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: showContent ? 1 : 0 }}
                    transition={{ duration: 2, ease: "easeInOut", delay: 1 }}
                >
                    {children}
                </Box>
                {!showContent && <IntroAnimation onComplete={handleAnimationComplete} />}
            </QueryClientProvider>
        </ChakraProvider>
    );
};

export default App;
