import * as React from "react"
import type { GatsbyBrowser } from "gatsby"
import App from "./src/App"
import "@fontsource/nunito";
import "@fontsource/nunito/700.css";
import "@fontsource/nunito/800.css";
import "@fontsource/nunito/900.css";
import "@fontsource/nunito/300.css";
import "@fontsource/nunito/400.css";
import "@fontsource/nunito/600.css";
import { CSSReset } from "@chakra-ui/react";

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
}) => {
  return (
    <>
      <CSSReset />
      <App>
        {element}
      </App>
    </>
  )
}

const scrollToAnchor = () => {
  const anchorId = window.location.hash ? window.location.hash.substring(1) : false;
  if (anchorId) {
    setTimeout(() => {
      const element = document.querySelector(`#${anchorId}`);

      if (element) {
        const offset = 120;
        const scrollY = window.pageYOffset || document.documentElement.scrollTop;
        const destination = element.getBoundingClientRect().top + scrollY - offset;
        window.scrollTo({ top: destination, behavior: 'smooth' });
      }
    }, 100);
  }
};

export const onClientEntry = () => {
  window.onload = () => {
    scrollToAnchor();
  };
}

export const onRouteUpdate = () => {
  scrollToAnchor();
}